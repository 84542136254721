import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes: [], //defined globally in main
    scrollBehavior () {
        //scroll to top on all page changes
        return { top: 0 }
    }
})
export default router
